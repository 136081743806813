import request from '@/utils/request'


// 查询服务内容列表
export function listContent(query) {
  return request({
    url: '/serve/serve-content/list',
    method: 'get',
    params: query
  })
}

// 查询服务内容分页
export function pageContent(query) {
  return request({
    url: '/serve/serve-content/page',
    method: 'get',
    params: query
  })
}

// 查询服务内容详细
export function getContent(data) {
  return request({
    url: '/serve/serve-content/detail',
    method: 'get',
    params: data
  })
}

// 新增服务内容
export function addContent(data) {
  return request({
    url: '/serve/serve-content/add',
    method: 'post',
    data: data
  })
}

// 修改服务内容
export function updateContent(data) {
  return request({
    url: '/serve/serve-content/edit',
    method: 'post',
    data: data
  })
}

// 删除服务内容
export function delContent(data) {
  return request({
    url: '/serve/serve-content/delete',
    method: 'post',
    data: data
  })
}
