<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle == '添加' ? $t('通用.新增') : $t('通用.修改') }} </b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!-- <a-form-model-item label="图标" prop="icon" >
        <a-input v-model="form.icon" placeholder="请输入图标" />
      </a-form-model-item> -->
      <a-form-model-item :label="$t('服务管理.服务内容管理.图片')" prop="image">
        <file-upload type="image" :defaultList="form.iconArr" :count="1" @input="getImg($event, 'icon')"></file-upload>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务内容管理.名称')" prop="name">
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务内容管理.描述')" prop="describe">
        <a-input v-model="form.describe" placeholder="请输入描述" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.服务内容管理.是否启用')" prop="status">
        <a-select v-model="form.status" style="width: 100%" placeholder="请选择状态">
          <a-select-option :value="0"> 禁用 </a-select-option>
          <a-select-option :value="1"> 启用 </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm"> {{$t('通用.取消')}} </a-button>
          <a-button type="dashed" @click="cancel"> {{ $t('通用.保存') }} </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getContent, addContent, updateContent } from '@/api/serve/content'
import { guid } from '@/utils/ruoyi'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      iconArr: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        icon: null,

        name: null,

        describe: null,

        status: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        icon: [{ required: true, message: '图标不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        describe: [{ required: true, message: '描述不能为空', trigger: 'blur' }],
        status: [{ required: true, message: '是否启用,0-禁用,1-启用不能为空', trigger: 'blur' }],
      },
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info',info)
      // if (info.length === 0) {
      //   this.form[fieldName] = ''
      //   this.form[`${fieldName}Arr`] = []
      // } else {
        // if (this.form[`${fieldName}Count`] === 1) {
        //   // this.form[fieldName] = info[0].url
        //   this.form[icon] = info[0].url
        //   this.form[`${fieldName}Arr`] = info
        // } else {
        //   // this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
        //   this.form[icon] = info.map(ele => { return ele.url }).join(",")
        //   this.form[`${fieldName}Arr`] = info
        // }
      // }
        this.form.icon = info[0].url

    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        icon: null,
        iconArr: [],
        name: null,
        describe: null,
        status: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      // console.log('row',row)
      this.open = true
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getContent({ id: id }).then((response) => {
        this.form = response.data

        // 图片回显
        let that = this
        this.form.iconArr = []
        if (this.form.icon) {
          this.form.iconArr.push({
            uid: guid(8, 10),
            name: that.form.icon,
            status: 'done',
            halfUrl: that.form.icon,
            url: that.form.icon,
            path: that.form.icon,
          })
        }
        this.form.icon = row.icon
      
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      // const url = this.form.icon[0].path
      // this.form.icon = url
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateContent(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addContent(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
